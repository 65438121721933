import { html, render, TemplateResult } from "lit";

export const removeLaunchScreen = () => {
  const initEl = document.getElementById("taui-launch-screen");
  if (initEl) {
    initEl.parentElement!.removeChild(initEl);
  }
};

export const renderLaunchScreenInfoBox = (content: TemplateResult) => {
  const infoBoxElement = document.getElementById("taui-launch-screen-info-box");
  if (infoBoxElement) {
    render(content, infoBoxElement);
  }
};

export const renderLaunchScreenLogo = (string: string) => {
  const logoElement = document.getElementById("taui-launch-screen-logo");
  if (logoElement) {
    render(html`<img src=${string} alt="" />`, logoElement);
  }
};
